export const environment =
{
  production: true,
  apiURL: "/v1/",
  apiURL2: "/v2/",
  chatURL: 'wss://chat.lynkit.in/socket.io',
  socketURL: '/',
  versionCheckURL: "/version.json",
  auth_token: 'key',
  sso_token: 'sso_key',
  encDensKey: "B7@3N4$1R5%2J^6A9*I8(G)B~A-L",
  mapbox: {
    accessToken: 'pk.eyJ1IjoieW9naWU2NCIsImEiOiJjbHFkb2M4OHMwYm05MnFtZXhrZ3lraGw3In0.WzwvOmkRqFGugMUP74Kh-w'
  }
};
