import { Injectable } from '@angular/core';
import ExcelJS from 'exceljs';
import * as FileSaver from 'file-saver';

@Injectable({
    providedIn: 'root'
})
export class ExcelService {

    constructor() { }

    async readExcelFile(file: File): Promise<any[][]> {
        const workbook = new ExcelJS.Workbook();
        const fileReader = new FileReader();

        return new Promise<any[][]>((resolve, reject) => {
            fileReader.onload = (event) => {
                const arrayBuffer = (event.target as FileReader).result as ArrayBuffer;
                workbook.xlsx.load(arrayBuffer).then(() => {
                    // console.log(workbook);
                    const worksheet = workbook.getWorksheet();
                    // console.log(worksheet);
                    const headers: string[] = [];
                    const rows: any[] = [];

                    try {
                        worksheet.eachRow((row, rowNumber) => {
                            const rowData: any = {};
                            if (rowNumber === 1) {
                                row.eachCell((cell: any) => {
                                    headers.push(cell.value);
                                });
                            } else {
                                row.eachCell((cell, columnIndex) => {
                                    rowData[headers[columnIndex - 1]] = cell.value;
                                });
                                rows.push(rowData);
                            }
                        });

                        resolve(rows);
                    }
                    catch (error) {
                        reject(error);
                    }
                })
                    .catch((error) => {
                        reject(error);
                    });
            };

            fileReader.onerror = (error) => {
                reject(error);
            };

            fileReader.readAsArrayBuffer(file);
        });
    }

    async writeExcelFile(data, sheetName, fileName) {
        const workbook = new ExcelJS.Workbook();
        workbook.creator = 'Me';
        workbook.created = new Date();
        const worksheet = workbook.addWorksheet(sheetName, { views: [{ state: 'frozen', xSplit: 1, ySplit: 1 }] });

        // Find dates from 'Net' _id and sort them
        const netData = data.find(item => item._id === 'Net')?.data || [];
        const ratingData = data.find(item => item._id === 'Rating')?.data || [];
        const dates = netData.map((entry: any) => entry.date).sort();

        // Add headers
        const headers = ['Crane', ...dates];
        const headerRow = worksheet.addRow(headers);

        headerRow.eachCell((cell, colNumber) => {
            cell.font = { bold: true };
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFFF00' }
            };
        });


        // Add rows for each crane data
        data.filter(item => (item._id !== 'Net' && item._id !== 'Rating')).forEach(item => {
            const row: any[] = [item._id];
            dates.forEach(date => {
                const dataEntry = item.data.find((entry: any) => entry.date === date);
                row.push(dataEntry && dataEntry.capturedPercentage ? parseFloat(dataEntry.capturedPercentage).toFixed(1) : 0);
            });
            const newRow = worksheet.addRow(row);
            newRow.getCell(1).font = { bold: true, }; // Make the first column bold
        });

        // Add Net data
        const netRow: any[] = ['Net'];
        dates.forEach(date => {
            const netEntry = netData.find((entry: any) => entry.date === date);
            netRow.push(netEntry && netEntry.totalCapturedPercentage ? parseFloat(netEntry.totalCapturedPercentage).toFixed(1) : 0);
        });
        const netDataRow = worksheet.addRow(netRow);
        netDataRow.getCell(1).font = { bold: true }; // Make the first column bold

        // Add Rating data
        
        if (fileName.includes('Crane')) {
            const ratingRow: any[] = ['Rating'];
            dates.forEach(date => {
                const ratingEntry = ratingData.find((entry: any) => entry.date === date);
                ratingRow.push(ratingEntry && ratingEntry.craneRating ? parseFloat(ratingEntry.craneRating).toFixed(1) : 0);
            });

            const ratingDataRow = worksheet.addRow(ratingRow);
            ratingDataRow.getCell(1).font = { bold: true }; // Make the first column bold
        }

        workbook.xlsx.writeBuffer().then((buffer: BlobPart) => {
            const blob = new Blob([buffer], { type: 'application/octet-stream' });
            FileSaver.saveAs(blob, sheetName + "_" + fileName + '.xlsx');
        });
    }

    async writeExcelFileForFix(data, sheetName, fileName) {
        const workbook = new ExcelJS.Workbook();
        workbook.creator = 'Me';
        workbook.created = new Date();
        const worksheet = workbook.addWorksheet(sheetName, { views: [{ state: 'frozen', xSplit: 1, ySplit: 1 }] });

        // Find dates from 'Net' _id and sort them
        const netData = data.find(item => item._id === 'Net')?.data || [];
        var dates = [];
        var mergeableDates = [];
        netData.map((entry: any) => {
            dates.push(entry.date);
            mergeableDates.push(entry.date)
            mergeableDates.push(entry.date)//duplicate entry for merging columns for fix 4 and 5
        });

        dates.sort();
        mergeableDates.sort();

        // Add headers
        const headers = ['Crane', ...mergeableDates];
        console.log(headers);

        const headerRow = worksheet.addRow(headers);

        mergeableDates.forEach((date, index) => {
            const startCell: any = worksheet.getCell(1, index == 0 ? index + 2 : index + index + 2); // 1-based index for rows and columns in ExcelJS
            const endCell: any = worksheet.getCell(1, index == 0 ? index + 3 : index + index + 3); // endCell is the next cell in the row
            worksheet.mergeCells(`${startCell._address}:${endCell._address}`);
        });

        // var headersFix = [''];
        // headers.forEach(x => {
        //     headersFix.push('Fix 4')
        //     headersFix.push('Fix 5')
        // })
        // worksheet.addRow(headersFix);

        headerRow.eachCell((cell, colNumber) => {
            cell.font = { bold: true };
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFFF00' }
            };
        });

        // Add rows for each crane data
        data.filter(item => item._id !== 'Net').forEach(item => {
            const row: any[] = [item._id];
            dates.forEach(date => {
                const dataEntry = item.data.find((entry: any) => entry.date === date);
                row.push(dataEntry ? dataEntry.fourFix : 0);
                row.push(dataEntry ? dataEntry.fiveFix : 0);
            });
            const newRow = worksheet.addRow(row);
            newRow.getCell(1).font = { bold: true, }; // Make the first column bold
        });

        // Add Net data
        const netRow: any[] = ['Net'];
        dates.forEach(date => {
            const netEntry = netData.find((entry: any) => entry.date === date);
            netRow.push(netEntry && netEntry.totalCapturedPercentage ? parseFloat(netEntry.totalCapturedPercentage).toFixed(1) : 0);
            netRow.push(netEntry && netEntry.totalCapturedPercentage ? parseFloat(netEntry.totalCapturedPercentage).toFixed(1) : 0);
        });
        const netDataRow = worksheet.addRow(netRow);
        netDataRow.getCell(1).font = { bold: true }; // Make the first column bold

        netData.forEach((date, index) => {
            const startCell: any = worksheet.getCell(data.length + 1, index == 0 ? index + 2 : index + index + 2); // 1-based index for rows and columns in ExcelJS
            const endCell: any = worksheet.getCell(data.length + 1, index == 0 ? index + 3 : index + index + 3); // endCell is the next cell in the row
            worksheet.mergeCells(`${startCell._address}:${endCell._address}`);
        });


        worksheet.columns.forEach(column => {
            let maxLength = 0;
            column.eachCell({ includeEmpty: true }, cell => {
                const columnLength = cell.value ? cell.value.toString().length : 10;
                if (columnLength > maxLength) {
                    maxLength = columnLength;
                }
            });
            column.width = maxLength + 2; // Add some padding to the width
        });

        workbook.xlsx.writeBuffer().then((buffer: BlobPart) => {
            const blob = new Blob([buffer], { type: 'application/octet-stream' });
            FileSaver.saveAs(blob, sheetName + "_" + fileName + '.xlsx');
        });
    }
}

