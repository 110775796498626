import { Injectable } from '@angular/core';

// import {Observable} from 'rxjs/observable';
import { Observable, Observer } from 'rxjs';
import { webSocket } from 'rxjs/webSocket';
import { io } from 'socket.io-client';
import { environment } from 'src/environments/environment';
// import * as socketIo from 'socket.io-client';
@Injectable()
export class SocketService {
  private BASE_URL = environment.socketURL;
  private socket;

  observer: Observer<number>;
  private webSocket: WebSocket;
  constructor() { }

  connectSocket(details) {
    // this.connect();

    var socketID = localStorage.getItem("socketID")
    var { _id, email, iat, name } = details;
    let sessionKey = localStorage.getItem("sessionKey");
    this.socket = io(this.BASE_URL,
      {
        transports:["websocket"], 
        autoConnect: true,
        query: {
          name, userId: _id, userName: email, iat, socketID, sessionKey
          // `name=${name}&userId=${_id}&userName=${email}&iat=${iat}&socketID=${socketID}&sessionKey=${sessionKey}`
        }
      });
    // this.socket = io(this.BASE_URL, 
    //   { query: `name=${name}&userId=${_id}&userName=${email}&iat=${iat}&socketID=${socketID}&sessionKey=${sessionKey}` });
  }

  disconnectSocket(type) {
    this.socket.emit('disconnect', { type });
  }

  getSocketID(): Observable<any> {
    if (this.socket) {
      this.socket.on('socket-added', (res) => {
        console.log("Socket Added", res.data);
        localStorage.setItem("socketID", res.data)
      });
    }
    return this.createObservable();
  }

  logout(userId, sessionKey) {
    if (this.socket) {
      this.socket.emit('logout', { userId, sessionKey });
    }
  }

  getSocket(): Observable<any> {
    if (this.socket) {
      this.socket.on('data', (res) => {
        this.observer.next(res.data);
      });
    }
    return this.createObservable();
  }

  createObservable(): Observable<number> {
    return new Observable<number>(observer => {
      this.observer = observer;
    });
  }

  // private handleError(error) {
  //   console.error('server error:', error);
  //   if (error.error instanceof Error) {
  //     let errMessage = error.error.message;
  //     return Observable.throw(errMessage);
  //   }
  //   return Observable.throw(error || 'Socket.io server error');
  // }

  markAllAsRead(depotID: string): Observable<any> {
    if (this.socket) {
      this.socket.emit('markAllAsRead', { depotID });
      return new Observable(observer => {
        this.socket.on('allReadNotifications', (data) => {
          observer.next(data);
        });
        this.socket.on('databaseError1', (err) => {
          console.log('Some errror occured', err);
        })
      })
    }
    return new Observable(null);
  }

  getNotifications(depotID: string): Observable<any> {
    if (this.socket) {
      this.socket.emit('getNotifications', { depotID });
      return new Observable(observer => {
        this.socket.on('notifications', (data) => {
          observer.next(data);
        });
        this.socket.on('databaseError', (err) => {
          console.log('Some errror occured', err);
        })
      });
    }
    return new Observable(null);
  }

  setReadStatusTrue(id: string): Observable<any> {
    if (this.socket) {
      this.socket.emit('setReadStatusTrue', { id });

      return new Observable((observer) => {
        this.socket.on('readStatusUpdated', (data) => {
          observer.next(data);
        });
        this.socket.on('empty', (data) => {
          console.log(data);
        });
        this.socket.on('dbError', (data) => {
          console.log('Some error occured', data);
        });
      })
    }
    else
    {
      return new Observable(null);
    }
  }

  connect(type, details) 
  {
    var { _id, email, iat, name, sessionKey,depotID } = details;

    this.webSocket = new WebSocket(environment.chatURL+"?"+`name=${name}&userId=${_id}&userName=${email}&depotID=${depotID ? depotID[0] : 'NA'}&sessionKey=${sessionKey}`);
    return this.webSocket;
    // if (type == 'open') {
    //   this.webSocket.onopen = () => {
    //     console.log('WebSocket connection established.');
    //     this.sendMessage("How many containers gate out") //where depotID is 6308a26d80708e7325e8ceac
    //   };
    // }

    this.webSocket.onopen = () => {
      console.log('WebSocket connection established.');
      // this.sendMessage("How many containers gate out") //where depotID is 6308a26d80708e7325e8ceac
    };

    // this.webSocket.onclose = (event) => {
    //   console.log('WebSocket connection closed:', event);
    // };

    // this.webSocket.onerror = (error) => {
    //   console.error('WebSocket error:', error);
    // };
  }

  sendMessage(message: string): void {
    this.webSocket.send(message);
  }

  closeConnection(): void {
    console.log('close AI socket');
    this.webSocket.close();
  }


}

interface Socket {
  on(event: string, callback: (data: any) => void);
  emit(event: string, data: any);
}