import { Injectable } from '@angular/core';
import { tsXLXS } from 'ts-xlsx-export';

@Injectable({
  providedIn: 'root'
})
export class DownloadReportsService {

  constructor() { }

  downloadExcel(data, name) {
    tsXLXS().exportAsExcelFile(data).saveAsExcelFile(name);
  }
}
