"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var FileSaver = require("file-saver");
var XLSX = require("xlsx");
exports.tsXLXS = function () {
  var xlsxLib = {
    buffer: '',
    exportAsExcelFile: function (json) {
      var worksheet = XLSX.utils.json_to_sheet(json);
      var workbook = {
        Sheets: {
          'data': worksheet
        },
        SheetNames: ['data']
      };
      this.buffer = XLSX.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });
      return this;
    },
    saveAsExcelFile: function (fileName) {
      var data = new Blob([this.buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
      });
      FileSaver.saveAs(data, fileName + '.xlsx');
      return this;
    }
  };
  return xlsxLib;
};