import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root'
})
export class StorageService {

    private routeURL: string = '';
    private page: string = '';

    private selectedYard: string = 'emptyYard';

    public data: Object | string;

    constructor() {
    }


    set setUrl(val: string) {
        this.routeURL = val
    }

    get getURL() {
        return this.routeURL
    }
   
    set setPage(val: string) {
        this.page = val
    }

    get getPage() {
        return this.page
    }

    set setYard(val: string) {
        this.selectedYard = val
    }

    get getYard() {
        return this.selectedYard
    }

    public getData(): Object | string {
        return this.data;
    }

    public setData(data: any): void {
        this.data = data;
    }
}